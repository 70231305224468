import React from "react"
import PropTypes from "prop-types"
import { Typography } from "@mui/material"

const SectionTitle = ({ subtitle, children, ...props }) => {
  return (
    <Typography
      variant={subtitle ? "h4" : "h3"}
      color={subtitle ? "primary" : "secondary"}
      sx={{ mb: 2 }}
      {...props}
    >
      <strong>{children}</strong>
    </Typography>
  )
}

SectionTitle.propTypes = {
  children: PropTypes.node,
}

export default SectionTitle
